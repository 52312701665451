import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import NewsletterForm from "../components/NewsletterForm"

class BlogIndex extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const posts = data.allPosts.edges

        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage =
            currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
        const nextPage = (currentPage + 1).toString()
        const tags = data.tagsGroup.group

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title="All posts"
                    keywords={[
                        `blog`,
                        `html`,
                        `javascript`,
                        `react`,
                        `css`,
                        `accessibility`,
                        `career`,
                    ]}
                />
                <section className="blog-list-wrapper">
                    <div style={{ flex: 2, marginRight: "24px" }}>
                        <h1 style={{ marginBottom: "60px", marginTop: 0 }}>
                            Blog
                        </h1>
                        <ul className="blog-posts-container">
                            {posts.map(({ node }) => {
                                const title =
                                    node.frontmatter.title || node.fields.slug
                                return (
                                    <li
                                        key={node.fields.slug}
                                        style={{
                                            marginBottom: rhythm(2),
                                        }}
                                    >
                                        <small>
                                            {node.frontmatter.date}{" "}
                                            {node.frontmatter.publishedFor && (
                                                <span>
                                                    &nbsp; | &nbsp;{" "}
                                                    <span
                                                        style={{
                                                            color:
                                                                "var(--secondary-main-color)",
                                                        }}
                                                    >
                                                        for{" "}
                                                        {
                                                            node.frontmatter
                                                                .publishedFor
                                                        }
                                                    </span>
                                                </span>
                                            )}
                                        </small>
                                        <h2
                                            style={{
                                                marginBottom: rhythm(1 / 3),
                                                marginTop: rhythm(1 / 4),
                                            }}
                                        >
                                            <Link
                                                to={node.fields.slug}
                                                className="blog-post-link"
                                            >
                                                {title}
                                            </Link>
                                        </h2>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    node.frontmatter
                                                        .description ||
                                                    node.excerpt,
                                            }}
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        <nav
                            className="pagination-container"
                            aria-label="Pagination"
                        >
                            <ul className="pagination-wrapper">
                                {!isFirst && (
                                    <Link
                                        to={`/blog/${prevPage}`}
                                        rel="prev"
                                        aria-hidden="true"
                                    >
                                        &#171;
                                    </Link>
                                )}
                                {Array.from({ length: numPages }, (_, i) => (
                                    <li
                                        key={`pagination-number${i + 1}`}
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        <Link
                                            to={`/blog/${i === 0 ? "" : i + 1}`}
                                            className="pagination-number"
                                            style={{
                                                background:
                                                    i + 1 === currentPage
                                                        ? "var(--primary-color)"
                                                        : "var(--secondary-color)",
                                            }}
                                        >
                                            {i + 1}
                                        </Link>
                                    </li>
                                ))}
                                {!isLast && (
                                    <Link
                                        to={`/blog/${nextPage}`}
                                        rel="next"
                                        aria-hidden="true"
                                    >
                                        &#187;
                                    </Link>
                                )}
                            </ul>
                        </nav>
                    </div>
                    <div style={{ flex: 1 }}>
                        <nav aria-label="Browse posts by topic">
                            <ul className="tags-list">
                                <h2></h2>
                                {tags.map(({ tag, totalCount }) => {
                                    return (
                                        <li key={tag}>
                                            <Link to={`/tags/${tag}/`}>
                                                {tag} ({totalCount})
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                        <div class="newsletter-homepage">
                            <div class="newsletter-home-container newsletter-card">
                                <p>
                                    I share what I learn while I navigate the
                                    deep trenches of software development.
                                    Subscribe to the newsletter!
                                </p>
                                <NewsletterForm />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
export const pageQuery = graphql`
    query blogPageQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allPosts: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        publishedFor
                    }
                }
            }
        }

        tagsGroup: allMarkdownRemark {
            group(field: frontmatter___tags) {
                tag: fieldValue
                totalCount
            }
        }
    }
`
export default BlogIndex

import React from "react"

const NewsletterForm = () => {
    return (
        <form
            action="https://buttondown.email/api/emails/embed-subscribe/ananyaneogi"
            method="post"
            target="popupwindow"
            onsubmit="window.open('https://buttondown.email/ananyaneogi', 'popupwindow')"
            class="embeddable-buttondown-form newsletter-form"
        >
            <label for="bd-email" class="sr-only">
                Enter your email
            </label>
            <input
                type="email"
                name="email"
                id="bd-email"
                placeholder="your email"
            />
            <input type="hidden" value="1" name="embed" />
            <input type="submit" value="Subscribe" className="button-link" />
        </form>
    )
}

export default NewsletterForm
